const swiper = new Swiper(".swiper", {
  loop: true,
  effect: "fade",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

$(document).on("click", ".context-menu-opener", function () {
  let parentElement = $(this).parent().parent().parent();

  // Przełączanie klas dla różnych elementów
  parentElement
    .children(".context-menu, .menu-3,  .menu-4")
    .toggleClass("d-flex");
  parentElement.find(".menu-2").toggleClass("d-flex");
  $(this).toggleClass("opened");

  // Usunięcie klas, jeśli element nie ma klasy "opened"
  if (!$(this).hasClass("opened")) {
    parentElement
      .find(".menu-4, .menu-3, .menu-2, .context-menu")
      .removeClass("d-flex");
    parentElement.find(".opened").removeClass("opened");
  }
});
// $(document).on("mouseenter", ".menu-item", function () {
//   $(this).css("borderLeft", "1px solid white");
// });
// $(document).on("mouseleave", ".menu-item", function () {
//   $(this).css("borderLeft", "transparent");
// });
// $(document).ready(function () {
//   if ($(window).width() < 992) {
//     let halfNavHeight = $(".header__nav--lower").height();
//     $(".context-menu").css(
//       "height",
//       halfNavHeight - $(".header__nav--lower ul li").height()
//     );
//   }
// });

$(document).ready(function () {
  if ($(window).width() < 992) {
    let halfNavHeight = $(".header__nav--lower").height();
    let liHeight = $(".header__nav--lower ul li").height();

    $(".context-menu").each(function (index) {
      let newHeight = halfNavHeight - liHeight * (index + 1); // Dodanie 1 do indeksu, jeśli chcesz zacząć od 1 zamiast od 0
      $(this).css("height", 'auto');
    });
  }
});

$(".hamburger").click(function () {
  $(".header__container").toggleClass("header__container--active");
  $(".hamburger").toggleClass("hamburger--active");
  $(".search").removeClass("open");
});
$(document).click(function (event) {
  if (
    !$(event.target).is(
      ".hamburger, .hamburger span, .dropdown, .dropdown-toggle, .context-menu-opener"
    )
  ) {
    $(".header__container").removeClass("header__container--active");
    $(".hamburger").removeClass("hamburger--active");
  }
});
$(document).on("mouseenter", ".brand", function () {
  $(this)
    .children(".on-hover")
    .find("*")
    .css("transition-delay", 0 + "s");
});
const makeTime = function () {
  $(".brand").each(function () {
    let contentHeight = $(this).children(".brand__content").height();

    $(this)
      .children(".on-hover")
      .find("*")
      .css("transition-delay", contentHeight / 1000 + "s");
  });
};
$(document).on("mouseleave", ".brand", function () {
  makeTime();
});
$(document).ready(makeTime());
// $(".brand").click(function () {
//   if (!$(this).hasClass("active")) {
//     $(this).addClass("active");
//   } else {
//     $(this).removeClass("active");
//   }
// });
$(".accordion-button").click(function () {
  const allCollapsesElementsNotShow = document.querySelectorAll(
    ".accordion-collapse:not(.show)"
  );
  const allCollapsesElements = document.querySelectorAll(".accordion-collapse");
  if (
    allCollapsesElementsNotShow.length == allCollapsesElements.length &&
    $(this).hasClass("collapsed")
  ) {
    $(".accordions-hide").removeClass("d-none");

    $(".accordions-show").addClass("d-none");
  } else {
    $(".accordions-show").removeClass("d-none");

    $(".accordions-hide").addClass("d-none");
  }
});
$(".accordions-toggler").click(function () {
  const collapseElementList = document.querySelectorAll(".collapse.show");
  const allCollapsesElements = document.querySelectorAll(
    ".collapse:not(.show)"
  );
  const collapseList = [...collapseElementList].map(
    (collapseEl) => new bootstrap.Collapse(collapseEl)
  );
  // Jeżeli ilość elementów otwartych jest rowna wszystkim elementom wtedy
  if (collapseElementList.length == 0) {
    const collapseList = [...allCollapsesElements].map(
      (collapseEl) => new bootstrap.Collapse(collapseEl)
    );
    $(".accordions-show").removeClass("d-none");
    $(".accordions-hide").addClass("d-none");
  } else {
    $(".accordions-hide").removeClass("d-none");
    $(".accordions-show").addClass("d-none");
  }
});
$(".my-slick-2 .slick-slide").click(function () {
  slider = setInterval(function () {
    if ($(".slick-slide").width() > 100) {
      $(".modal-content").addClass("content-on");
      $(".slider-box").addClass("add-visibility");
      clearInterval(slider);
    }
  }, 400);
});

$(document).ready(function () {
  $("select").niceSelect();
});
let absoluteSections = [];
$(document).ready(function () {
  absoluteSections = $(".absolute-section");
  let mapAbsolutes = absoluteSections.map(myAbsolutes);
  function myAbsolutes(index) {
    let section = absoluteSections.get(index);
    let sectionText = $(section).find(".absolute__half-text");

    let setHeight = function () {
      let textHeight = $(section).find(".absolute__half-text").height();
      let imgHeight = $(section).find(".absolute__half img").height();
      let heightDifference = imgHeight - textHeight;
      if (heightDifference > 0 && $(window).width() > 575) {
        $(sectionText).css("marginBottom", heightDifference);
      }
    };
    $(".absolute__half img").on("load", setHeight);
    $(window).resize(setHeight);
  }
});

//or however you get a handle to the IMG

$(document).ready(function () {
  const slickSliders = $(".slick-container");
  const mapSliders = slickSliders.map(mySliders);

  function mySliders(index) {
    $(this)
      .children(".slick-sliders")
      .addClass("my-slick-1" + index);
    $(this)
      .find(".slick-arrow-prev")
      .addClass("slick-arrow-prev" + index);
    $(this)
      .find(".slick-arrow-next")
      .addClass("slick-arrow-next" + index);
    $(this)
      .children(".slick-sliders.my-slick")
      .slick({
        infinite: true,
        dots: true,
        slidesToScroll: 1,
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
        responsive: [
          {
            breakpoint: 767,
            settings: {
              dots: false,
            },
          },
        ],
      });
    $(this)
      .children(".slick-sliders.my-slick-2")
      .slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        asNavFor: ".my-slick-4",
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              autoplay: false,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              autoplay: true,
              autoplaySpeed: 2000,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this)
      .children(".slick-sliders.my-slick-5")
      .slick({
        infinite: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              autoplay: false,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              autoplay: true,
              autoplaySpeed: 2000,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this)
      .children(".slick-sliders.my-slick-3")
      .slick({
        infinite: true,
        arrows: true,
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
      });
    $(this)
      .children(".slick-sliders.my-slick-4")
      .slick({
        infinite: true,
        arrows: true,
        slidesToScroll: 1,
        asNavFor: ".my-slick-2",
        prevArrow: $(".slick-arrow-prev" + index),
        nextArrow: $(".slick-arrow-next" + index),
      });
  }
});

$(document).ready(function () {
  if ($(".contact__adresses").is(":visible")) {
    let ems = $(".contact__adresses").find("em");
    $(ems).parent().parent().css("lineHeight", "1");
  }
});
let datesArray = [];
$(document).ready(function () {
  const slideDates = $(".slick-slide:not(.slick-cloned) h3");
  const mapSlides = slideDates.map(slides);
  function slides(index) {
    datesArray[index] = $(this).text();
  }
  const historyDates = $(".slick-dots button");
  const mapDates = historyDates.map(dates);
  function dates(index) {
    $(this).text(datesArray[index]);
  }
});
// Initialize and add the map
function initMap() {
  // The location of localization

  const localization = { lat: 52.35378527224951, lng: 16.89045052991007 };
  // The map, centered at localization
  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: 15,
    center: localization,
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }], // Turn off POI.
      },
      {
        featureType: "transit.station",
        stylers: [{ visibility: "off" }], // Turn off bus, train stations etc.
      },
    ],
  });

  var image = "../assets/img/local-icon.jpg";
  // The marker, positioned at localization
  const marker = new google.maps.Marker({
    position: localization,
    map: map,
    icon: {
      path: "M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z",
      fillColor: "black",
      scale: 0.4,
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "black",
      anchor: new google.maps.Point(10, 27),
    },
  });
}
$(document).ready(function () {
  $(".search__input").on("focus", function () {
    $(".search").addClass("active");
    $(".search-opener").addClass("active");
  });
  $(".search__input").on("blur", function () {
    $(".search").removeClass("active");
    $(".search-opener").removeClass("active");
  });
});
window.initMap = initMap;
$(".lost").click(function () {
  $(".login-reset").removeClass("d-none");
  $(".login-hide").addClass("d-none");
});
const button = document.querySelectorAll(".my-slick-2 .slick-slide");
let mousedownTime;

$(".my-slick-2 .slick-slide").mousedown(function () {
  mousedownTime = new Date().getTime();
});
$(".my-slick-2 .slick-slide").mouseup(function () {
  const mouseupTime = new Date().getTime();
  timeDifference = mouseupTime - mousedownTime;
  if (timeDifference < 350) {
    $(".modal-slider").modal("show");
  }
});
$(".btn-close-slider").click(function () {
  $(".modal-slider").modal("hide");
});

$(document).ready(function () {
  $(".map__image").removeClass("active");
  if ($("input[id='flexRadioDefault1']").is(":checked")) {
    $(".map__image--profix").addClass("active");
  } else {
    $(".map__image--simplex").addClass("active");
  }
});
$(".map__changer").click(function () {
  $(".map__image").removeClass("active");
});
$(".map__changer--blue").click(function () {
  $(".map__image--profix").addClass("active");
});
$(".map__changer--yellow").click(function () {
  $(".map__image--simplex").addClass("active");
});
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (
    prevScrollpos < currentScrollPos &&
    currentScrollPos > $(".banner").height()
  ) {
    $(".search").removeClass("open");
    $(".search-opener").removeClass("active");

    // if ($(".search").hasClass("open")) {
    //   setTimeout(function () {
    //     $("#navbar").addClass("hide");
    //     $(".header__container").removeClass("header__container--active");
    //     $(".hamburger").removeClass("hamburger--active");
    //   }, 600);
    // } else {
    $("#navbar").addClass("hide");
    $(".header__container").removeClass("header__container--active");
    $(".hamburger").removeClass("hamburger--active");
    // }
  } else {
    $("#navbar").removeClass("hide");
  }
  prevScrollpos = currentScrollPos;
};

var sParallax = document.getElementsByClassName("sparallax");
new simpleParallax(sParallax, {
  delay: 0.6,
  transition: ".35,.13,.59,.82",
  scale: 1.15,
});
var shapesParallax = document.getElementsByClassName("shapesparallax");
new simpleParallax(shapesParallax, {
  delay: 1,
  transition: ".35,.13,.59,.82",
  scale: 2.8,
});

var scaleUp = document.getElementsByClassName("sparallax-scale-up");
new simpleParallax(scaleUp, {
  delay: 0.8,
  transition: ".35,.13,.59,.82",
  scale: 1.3,
});

$(document).on("click", ".list li", function () {
  if ($(this).is(".list li:first")) {
    $(".current").removeClass("color-primary");
  } else {
    $(".current").addClass("color-primary");
  }
});

$(document).ready(function () {
  if ($(".history-slider .slick-dots li").is(":visible")) {
    let dates = $(".history-slider .slick-dots li");
    if (dates.length % 2 === 0) {
      dates[dates.length - 1].classList.add("even");
    } else {
      dates[dates.length - 1].classList.add("odd");
    }
  }
});
  $(".check-opener").on("click", function (e) {
    event.preventDefault();
    // $(this).preventDefault();
    $(this).next().toggleClass("opened");
  });
$(document).ready(function () {
  if ($(".persons-slick").is(":visible")) {
    $(".persons-slick .slick-slide").css(
      "height",
      $(".persons-slick").height()
    );
  }
  setTimeout(function () {
    $(".history-slider .slick-slide").css(
      "height",
      $(".history-slider").height()
    );
  }, 100);
});
$(document).on("click", ".search-opener", function () {
  $(".search").toggleClass("open");
  if (!$(".search").hasClass("open")) {
    $(".search__input").blur();
  } else {
    $(".search__input").focus();
  }
});
$(document).ready(function () {
  $(document).on("click", function (event) {
    if (
      !$(event.target).closest(".search").length &&
      !$(event.target).closest(".search-opener").length
    ) {
      $(".search").removeClass("open");
    }
  });
});
$(document).ready(function () {
  if ($(window).width() < 767) {
    $(".history .slick-arrow").css(
      "top",
      79 + $(".history-slider .zoom").width() * (9 / 16)
    );
  }
});
$(document).on("click", ".custom-prev", function () {
  $(".slick-sliders.my-slick").slick("slickPrev");
});
$(document).on("click", ".custom-next", function () {
  $(".slick-sliders.my-slick").slick("slickNext");
});
